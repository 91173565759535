<template>
  <div class="scroll__wrapper">
    <div class="section chat-dialog pa-0" :class="{ 'col-8': contactDetails }">
      <div class="content">
        <v-app-bar
          flat
          color="#fff"
          @click="contactDetails = true"
          class="cursor-pointer"
        >
          <v-icon
            v-if="$vuetify.breakpoint.smAndDown"
            class="mr-4"
            @click.stop="$router.push({ name: 'chats' })"
          >
            fas fa-chevron-left
          </v-icon>
          <!-- <v-avatar size="40" elevation="10" class="mr-2">
              <img :src="getPartnerAvatar" />
            </v-avatar> -->
          <v-toolbar-title class="title pa-0 ma-0 d-flex flex-column">
            <span style="font-size: 20px; line-height: initial"> Admin </span>
            <!-- <span style="font-size: 13px; line-height: initial"
                >@{{ user.first_name }}</span
              > -->
          </v-toolbar-title>
          <template v-if="pinnedMessages.length" #extension>
            <div @click="changeSlide" class="pinned-messages">
              <div class="pinned-messages__indicators">
                <div
                  class="dot"
                  :class="{ active: index === model }"
                  v-for="(i, index) in pinnedMessages.length"
                  :key="i"
                  @click.stop="model = index"
                >
                </div>
              </div>
              <span class="pinned-messages__title">
                Закрепленное сообщение #{{ model + 1 }}
              </span>
              <v-carousel hide-delimiters vertical v-model="model" reverse>
                <v-carousel-item v-for="msg in pinnedMessages" :key="msg.id">
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <div class="pa-0 pinned-messages__body">
                      {{ msg.body }}
                    </div>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </div>
          </template>
        </v-app-bar>
      </div>
      <div
        class="scrollable d-flex flex-column pa-4"
        ref="messages"
        @scroll="loadMore"
      >
        <div
          v-for="(msg, index) in messages"
          :key="msg.id"
          class="d-flex align-end message"
          :id="'message-' + msg.id"
          :class="[
            msg.showAvatar ? 'mb-5' : 'mb-1',
            isClient(msg.author_type) ? 'me' : 'partner',
            {
              'last-message': index === messages.length - 1,
              highlight:
                pinnedMessageHighlighted && scrollToPinnedMessageId === msg.id,
            },
          ]"
        >
          <v-avatar size="30" elevation="10" class="mr-3">
            <!-- <img
                v-show="msg.me ? false : msg.showAvatar"
                :src="msg.authorAvatar"
              /> -->
          </v-avatar>
          <div
            v-if="!msg.author.full_name && !showTryAgain"
            class="loader"
          ></div>
          <div
            v-if="!msg.author.full_name && showTryAgain === msg.content"
            @click="
              resendMessage({
                value: showTryAgain,
                type: 'text',
              })
            "
          >
            <i class="repeat fas fa-repeat"></i>
          </div>
          <div class="d-flex flex-column">
            <span
              class="message_date"
              :class="msg.author_type !== 'client' && 'message_date_end'"
              >{{ msg.created_at }}</span
            >
            <div class="parent_msg" v-show="msg.parent_id"
              >Reply to:
              <span>{{ replyMsg(msg.parent_id)?.content }}</span></div
            >
            <v-card
              :color="
                msg.mark
                  ? msg?.mark
                  : isClient(msg.author_type)
                  ? 'rgb(31, 135, 120)'
                  : 'rgb(241, 241, 241)'
              "
              :dark="isClient(msg.author_type)"
              flat
              tile
              style="width: fit-content; align-self: flex-end"
              class="rounded-lg"
              :class="[
                isClient(msg.author_type) ? 'pl-3' : 'pr-3',
                {
                  'rounded-tl-0': !isClient(msg.author_type),
                  'rounded-l-0': !isClient(msg.author_type),
                  'rounded-r-0': isClient(msg.author_type),
                  'rounded-tr-0': isClient(msg.author_type),
                },
              ]"
            >
              <div style="max-width: 350px">
                <v-menu offset-y close-on-click>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs" class="message__actions">
                      <v-icon>mdi-chevron-down</v-icon>
                    </div>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        @click="replyMessage(msg)"
                        class="cursor-pointer"
                      >
                        Ответить
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list dense>
                        <v-list-item-title
                          @click.stop="setShowColors"
                          class="cursor-pointer"
                        >
                          Покрасить сообщение
                        </v-list-item-title>
                        <v-list>
                          <v-list-item v-for="color in colors" :key="color.id">
                            <v-list-item-content
                              @click="paintMessage(msg, color)"
                              :class="[
                                `color-item`,
                                `cursor-pointer`,
                                `color-item-${color.id}`,
                              ]"
                            >
                              {{ color.name }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-list-item>
                  <v-list-item-content>
                    <p
                      class="sender_name"
                      :class="
                        msg.author_type === 'client'
                          ? 'light_name'
                          : 'sender_name'
                      "
                      >{{ msg.author.full_name }}</p
                    >
                    <div v-if="msg.type_content === 'text'">{{
                      msg.content
                    }}</div>
                    <div
                      v-else-if="
                        (msg.type_content === 'file' &&
                          msg.file_format === 'jpeg') ||
                        msg.file_format === 'png'
                      "
                      class="image_message"
                    >
                      <a
                        :href="API_URL + '/storage/' + msg.content"
                        target="_blank"
                      >
                        <img
                          :src="API_URL + '/storage/' + msg.content"
                         :alt="getImageName(msg.content)"
                          :title="getImageName(msg.content)"
                        />
                      </a>
                    </div>
                    <div v-else>
                      <a
                        :href="API_URL + '/storage/' + msg.content"
                        target="_blank"
                      >
                        <i
                          v-if="msg.file_format === 'pdf'"
                          style="font-size: 25px"
                          class="mdi mdi-file-pdf"
                        ></i>
                        <i
                          v-else
                          style="font-size: 25px"
                          class="mdi mdi-file"
                        ></i>
                        <span class="white_color">{{ msg.content }}</span>
                      </a>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <span
                v-show="msg.is_read && msg.author_type !== 'manager'"
                :class="{ 'align-self-end': msg.author_type === 'client' }"
                class="seen_message"
              >
                <!-- v-show="msg.showTime" -->
                seen
              </span>
            </v-card>
          </div>
        </div>
      </div>
      <div class="user-input px-4 py-2">
        <div class="d-flex" :class="files && 'whole_input'">
          <div
            class="d-flex mx-6 align-center"
            :class="{ 'flex-grow-1': files }"
          >
            <v-file-input
              v-model="files"
              ref="fileInput"
              placeholder="Upload your documents"
              label="File input"
              hide-details
              :hide-input="!files"
              prepend-icon="ri-image-line"
              class="ma-0 mr-2 file_input"
              :class="{ 'flex-grow-0 pa-0 ma-0': !files }"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <v-icon color="rgb(31, 135, 120)">ri-line</v-icon>
          </div>
          <form
            class="input_form"
            @submit.prevent="
              sendMessage({
                value: message,
                type: files ? 'file' : 'text',
              })
            "
          >
            <v-text-field
              v-show="messageInput"
              v-model="message"
              placeholder="Start a new message"
              class="mr-3 border custom-input"
              rounded
              dense
              append-icon="mdi-emoticon-happy-outline"
              filled
              hide-details
              clear-icon="mdi-close-circle"
              clearable
              type="text"
              @click:clear="clearMessage"
            >
              <template #append>
                <div v-show="replyableMessage?.content" class="reply_to"
                  ><v-icon
                    style="cursor: pointer"
                    @click="replyableMessage = null"
                    size="18"
                    >mdi-close</v-icon
                  >Reply to:
                  <span>{{ replyableMessage?.content }}</span>
                </div>
              </template>
            </v-text-field>
            <button type="submit">
              <v-icon :disabled="sendButtonDisabled"> mdi-send </v-icon>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="contactDetails" style="overflow-y: scroll" class="col-4 pa-0">
      <v-toolbar tile flat>
        <v-toolbar-title class="title pa-0 ma-0 d-flex">
          <v-icon @click="contactDetails = false" class="mr-7">
            mdi-close
          </v-icon>
          <div>
            <span>Profile</span>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-card tile flat class="mb-4">
        <!-- <div style="position: relative; padding-top: 100%; width: 100%">
            <v-img
              style="position: absolute; inset: 0"
              :src="getPartnerAvatar"
            ></v-img>
          </div> -->
        <v-card-text>
          <!-- {{ getPartnerName }} -->
        </v-card-text>
      </v-card>

      <v-card tile flat>
        <v-card-text class="pa-0">
          <div
            class="d-flex contact-details__tabs"
            style="max-width: 100%; overflow: scroll"
          >
            <div
              v-for="(tab, index) in ['Медиа', 'Файлы', 'Отметки']"
              class="contact-details__tab"
              :class="{
                'contact-details__tab--active': contactDetailsTab === index,
              }"
              :key="index"
              @click="contactDetailsTab = index"
            >
              {{ tab }}
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="media" v-show="contactDetailsTab === 0">
        <v-col
          v-for="(item, index) in media"
          cols="4"
          class="pa-1"
          :key="index"
        >
          <a :href="API_URL + '/storage/' + item.content" target="_blank">
            <div
              :style="{
                paddingTop: '100%',
                backgroundImage: `url('${
                  API_URL + '/storage/' + item?.content
                }')`,
                backgroundPosition: 'center',
                cursor: 'pointer',
              }"
            ></div>
          </a>
        </v-col>
      </div>
      <div v-show="contactDetailsTab === 1">
        список файлов

        <v-col
          v-for="(item, index) in fileMessages"
          cols="4"
          class="pa-1"
          :key="index"
        >
          <a :href="API_URL + '/storage/' + item.content" target="_blank">
            <div v-if="item.file_format === 'pdf'">
              <a
                :href="API_URL + '/storage/' + item.content"
                target="_blank"
                style="display: flex; align-items: center"
              >
                <i
                  v-if="item.file_format === 'pdf'"
                  style="font-size: 25px"
                  class="mdi mdi-file-pdf"
                ></i>
                <i v-else style="font-size: 25px" class="mdi mdi-file"></i>
                <span class="black_color">{{ item.content }}</span>
              </a>
            </div>
          </a>
        </v-col>
      </div>
      <div v-show="contactDetailsTab === 2">
        <v-list dense>
          <v-list-item-group v-for="color in colors" v-bind:key="color.id">
            <v-subheader>
              {{ color.name }}
            </v-subheader>
            <v-list dense>
              <v-list-item
                @click="scrollPinnedToMessageId(msg.id)"
                class="message mb-1 pinned-message"
                :style="{ backgroundColor: color.color, color: '#fff' }"
                v-for="msg in markedMessages.filter(
                  (item) => item.mark === color.value
                )"
                :key="msg.id"
              >
                <v-list-item-content>
                  <div
                    v-if="
                      msg.file_format === 'jpeg' || msg.file_format === 'png'
                    "
                  >
                    <a
                      :href="API_URL + '/storage/' + msg?.content"
                      target="_blank"
                    >
                      <img
                        :src="API_URL + '/storage/' + msg?.content"
                        :alt="getImageName(msg.content)"
                          :title="getImageName(msg.content)"
                      />
                    </a>
                  </div>
                  <div v-else-if="msg.type_content === 'text'">
                    {{ msg.content }}
                  </div>
                  <div v-else>
                    <a
                      :href="API_URL + '/storage/' + msg?.content"
                      target="_blank"
                    >
                      {{ msg.content }}
                    </a>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'

const {
  mapState: State_notif,
  mapActions: Actions_notif,
  mapMutations: Mutations_notif,
} = createNamespacedHelpers('notifications')
const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers('chats')
const {
  mapGetters: Getters_auth,
  mapActions: mapAuthActions,
  mapState: mapAuthState,
} = createNamespacedHelpers('auth')
import Pusher from 'pusher-js'

export default {
  name: 'ChatDialogWholesale',
  data() {
    return {
      password: 'Password',
      show: false,
      message: '',
      model: 4,
      pinnedMessages: [],
      pinnedMessageHighlighted: false,
      scrollToPinnedMessageId: null,
      files: null,
      currentMessages: [],
      messageInput: true,
      contactDetails: false,
      showTryAgain: '',
      contactDetailsTab: 0,
      audio: new Audio('/ayfon-sms.mp3'),
      userInteracted: false,
      filterByColor: '',
      coloredMessages: [],
      scrollTop: false,
      colors: [
        { id: 1, name: 'Красный', color: '#B14754', value: 'red' },
        { id: 2, name: 'Желтый', color: '#EAD99A', value: 'yellow' },
        { id: 3, name: 'Синий', color: '#0000FF', value: 'blue' },
        { id: 4, name: 'Оранжевый', color: '#FFA500', value: 'orange' },
      ],
      showColors: false,
      links: [],
      replyableMessage: null,
    }
  },
  computed: {
    ...Getters_auth(['currentUser']),
    ...mapAuthState(['API_URL']),
    ...mapState([
      'messages',
      'nextPage',
      'media',
      'markedMessages',
      'isScrollTop',
      'chatInfo',
      'fileMessages',
      'isNewMessage'
    ]),
    ...mapAuthState(['user']),
    // ...mapState(['messages']),
    ...mapGetters(['getMessagesByChatId']),
    // messages() {
    //   const chatId =
    //     this.currentUser.role === 'retail_client' ? 1 : this.$route.params.id
    //   return this.getMessagesByChatId(chatId).messages
    // },
    // getPartnerName() {
    //   const chatId =
    //     this.currentUser.role === 'retail_client' ? 1 : this.$route.params.id
    //   return this.getMessagesByChatId(chatId).partnerName
    // },
    // getPartnerAvatar() {
    //   const chatId =
    //     this.currentUser.role === 'retail_client' ? 1 : this.$route.params.id
    //   return this.getMessagesByChatId(chatId).partnerAvatar
    // },
    sendButtonDisabled() {
      return !this.files && !this.message
    },
    filesItems() {
      return [
        {
          createdAt: new Date() - 100000000000,
          color: 'red',
          src: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'green',
          src: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'green',
          src: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'crimson',
          src: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'crimson',
          src: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'purple',
          src: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'violet',
          src: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          link: true,
        },
        {
          createdAt: new Date() - 100000000000,
          color: 'black',
          src: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        },
        {
          createdAt: new Date() - 200000000000,
          color: 'black',
          src: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        },
        {
          createdAt: new Date() - 200000000000,
          color: 'purple',
          src: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        },
        {
          createdAt: new Date() - 300000000000,
          color: 'crimson',
          src: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        },
      ]
    },

    filteredFilesItems() {
      return this.filterByColor
        ? this.filesItems.filter((item) => item.color === this.filterByColor)
        : this.filesItems
    },
  },
  watch: {
    filteredMessages(newValue, oldValue) {
      if (this.isNewMessage) {
        this.playNotificationSound();
      }
  },
    pinnedMessages(val) {
      this.model = val.length - 1
    },
    isScrollTop(val) {
      this.scrollToBottom()
    },
    files(val) {
      if (val) {
        this.messageInput = false
        const reader = new FileReader()
        reader.readAsDataURL(val)
        reader.onload = () => {
          const file = reader.result.split(',')[1]
          this.files = {
            type: this.getExtensionFromMimeType(val.type),
            file,
          }
        }
      } else {
        this.messageInput = true
      }
    },
  },
  methods: {
    getImageName,
    ...mapActions([
      'addMessage',
      'postMessageWholesale',
      'fetchMessagesWholesale',
      'makeMessagesReadedWholesale',
      'loadMoreMessagesWholesale',
      'markMessageByColorWholesale',
      'getMediaMessagesWholesale',
      'getMarkedMessagesWholesale',
      'fetchChatInfoWholesale',
    ]),
    ...mapMutations([
      'SET_READED_MESSAGES',
      'SET_MESSAGE_MARK',
      'SET_MEDIA_MESSAGE',
      'ADD_NEW_MESSAGE',
    ]),
    ...Actions_notif(['addNotification']),
    ...mapAuthActions(['fetchProfile']),
    getExtensionFromMimeType(mimeType) {
      switch (mimeType) {
        case 'image/jpeg':
          return 'jpeg'
        case 'image/jpg':
          return 'jpg'
        case 'image/png':
          return 'png'
        case 'application/zip':
          return 'zip'
        case 'application/rar':
          return 'rar'
        case 'application/pdf':
          return 'pdf'
        case 'text/plain':
          return 'txt'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'docx'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'xlsx'
        default:
          return ''
      }
    },
    resendMessage(data) {
      this.showTryAgain = ''
      this.sendMessage(data)
    },
    playNotificationSound() {
    this.audio.play().catch(error => {
      console.error('Ошибка воспроизведения звука:', error);
    });
  },
    async sendMessage({ value, type }) {
      let message
      if (type === 'file') {
        message = {
          message: this.files.file,
          type: type,
          parent_id: this.replyableMessage?.id || this.replyableMessage,
          file_format: this.files.type,
        }
      } else {
        message = {
          message: value,
          type: type,
          parent_id: this.replyableMessage?.id || this.replyableMessage,
        }
      }

      this.clearMessage()
      this.replyableMessage = null

      const res = await this.postMessageWholesale(message)
      if (res === 'error') {
        this.showTryAgain = message.message
      }
      if (res) {
        const data = this.fetchMessagesWholesale()
      }
    },
    replyMsg(parent_id) {
      return this.messages.find((el) => el.id === parent_id)
    },
    replyMessage(msg) {
      this.replyableMessage = msg
    },
    loadMore() {
      if (this.$refs.messages.scrollTop == 0) {
        this.loadMoreMessagesWholesale({ url: this.nextPage })
      }
    },
    clearMessage() {
      this.message = ''
      this.files = null
    },
    isClient(userType) {
      return userType !== "manager"
    },
    async changeSlide() {
      this.scrollToPinnedMessage()
      this.model = this.model - 1
      if (this.model < 0) {
        this.model = this.pinnedMessages.length - 1
      }
    },
    addToPinned(body, id, me) {
      if (this.pinnedMessages.length < 5) {
        const exist = this.pinnedMessages.find((msg) => msg.id === id)
        if (!exist) {
          this.pinnedMessages.push({ body, id, me })
        }
      }
    },
    deleteToPinned(id) {
      this.pinnedMessages = this.pinnedMessages.filter((item) => item.id !== id)
    },
    scrollToTarget(target, duration = 300) {
      const messagesContainer = '.scrollable'
      this.$vuetify.goTo(target, {
        container: messagesContainer,
        duration,
      })
    },
    async scrollToPinnedMessage() {
      const messageId = this.pinnedMessages[this.model].id
      const scrollTarget = `#message-${messageId}`
      await this.scrollToTarget(scrollTarget)
      this.highlightPinnedMessage(messageId)
    },
    async scrollPinnedToMessageId(messageId) {
      const scrollTarget = `#message-${messageId}`
      await this.scrollToTarget(scrollTarget)
      this.highlightPinnedMessage(messageId)
    },
    highlightPinnedMessage(messageId) {
      this.scrollToPinnedMessageId = messageId
      this.pinnedMessageHighlighted = true
      setTimeout(() => {
        this.scrollToPinnedMessageId = null
        this.pinnedMessageHighlighted = false
      }, 400)
    },
    containsURL(str) {
      const urlPattern =
        /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?/i
      return urlPattern.test(str)
    },
    setShowColors() {
      this.showColors = !this.showColors
    },
    paintMessage(msg, color) {
      this.showColors = false
      const obj = {
        chat_message_ids: [msg.id],
        mark: color.value,
      }
      const res = this.markMessageByColorWholesale(obj)
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messages
        messagesContainer.scrollTop = messagesContainer?.scrollHeight
      })
    },
    startInterval() {
      this.intervalId = setInterval(() => {
        this.fetchMessagesWholesale()
      }, 5000)
    },
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },
  },
  mounted() {
    const data = this.fetchMessagesWholesale()
    this.fetchChatInfoWholesale()
    this.getMediaMessagesWholesale()
    this.colors.forEach((item) => {
      this.getMarkedMessagesWholesale(item.value)
    })
    this.startInterval()

    document.addEventListener('click', () => {
    if (!this.userInteracted) {
      this.audio.muted = true;
      this.audio.play().then(() => {
        this.audio.pause();
        this.audio.muted = false;
        this.userInteracted = true;
      }).catch(error => {
        console.error('Ошибка при разблокировке звука:', error);
      });
    }
  });
  },
  beforeUnmount() {
    this.clearInterval()
  },
  created() {
    Pusher.logToConsole = true
    // const pusher = new Pusher('app-key', {
    //   cluster: 'ap2',
    //   wsHost: '127.0.0.1',
    //   wsPort: 6001,
    //   forceTLS: false,
    //   encrypted: true,
    //   disableStats: true,
    //   authEndpoint: 'http://127.0.0.1:8000/notification/auth',
    //   enabledTransports: ['ws', 'wss'],
    // })
    const pusher = new Pusher('695c3384e4e93035c74e', {
      cluster: 'ap2',
      wsHost: 'admin.tumar.com',
      wsPort: 49161,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      authEndpoint: 'https://admin.tumar.com/onlinecp/notification/auth',
      enabledTransports: ['ws', 'wss'],
    })
    this.fetchProfile()

    const userId = JSON.parse(localStorage.getItem('user_id'))
    const channel = pusher.subscribe(`chat-channel-${userId}`)
    channel.bind('new_message', (data) => {
      this.ADD_NEW_MESSAGE(data.message_data)
      this.scrollToBottom()
      this.SET_MEDIA_MESSAGE(data.message_data)
      const notification = {
        text: `
          Новое сообщение от пользователя: ${data.message_data.author.full_name},

          Текст сообщения: ${data.message_data.content}
          `,
        id: data.message_data.id,
        saving: data.message_data.author.full_name,
      }
      this.addNotification(notification)
    })
    channel.bind('read_message', (data) => {
      this.SET_READED_MESSAGES(data.chat_message_ids)
    })
    channel.bind('mark_message', (data) => {
      const message = {
        id: +Object.keys(data)[0],
        color: Object.values(data)[0],
      }
      this.SET_MESSAGE_MARK(message)
    })
    this.scrollToBottom()
    // channel.bind('subscription_succeeded', function () {
    //   console.log('Successfully subscribed to channel', channel)
    // })
  },
}
</script>
<style lang="scss">
.loader {
  width: 20px;
  max-width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  margin-right: 20px;
}
.repeat {
  margin-right: 20px;
  color: red;
}
.chat-dialog {
  .v-toolbar__content,
  .v-toolbar__extension {
    border-bottom: 1px solid #f2f2f2;
  }
  .v-input {
    &__slot {
      background-color: transparent !important;
    }
  }
  .v-window {
    height: 100% !important;
    &__container {
      .v-window__prev,
      .v-window__next {
        display: none;
      }
    }
    &-item {
      .v-image {
        height: 100% !important;
      }
    }
  }
  .user-input {
    .v-icon {
      color: rgb(31, 135, 120);
    }
  }
}
</style>
<style lang="scss" scoped>
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

.media {
  display: flex;
  flex-wrap: wrap;
}

.input_form {
  display: flex;
}

.message_date {
  font-size: 10px;
  text-align: end;
}

.message_date_end {
  font-size: 10px;
  text-align: start;
}

.custom-input {
  position: relative;
  height: auto;
}

.white_color {
  color: #fff;
}

.seen_message {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  color: #fff;
  font-size: 15px;
}
.custom-input .reply_to {
  // position: relative;
  // top: -25px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 2; /* Установка z-index выше, чем у встроенного текста инпута */
}
.parent_msg {
  font-weight: 600;
  color: dimgrey;
}
.scroll__wrapper {
  position: absolute;
  inset: 0;
  display: flex;
}
.section,
.scrollable {
  min-height: 0;
  flex-grow: 1;
}

.sender_name {
  font-size: 10px !important;
}
.file_input {
  max-width: 500px;
}
.whole_input {
  max-width: 350px;
}
.light_name {
  color: cyan;
  font-size: 11px !important;
}
.section {
  @include flex-column;
}
.scrollable {
  overflow: auto;
  background-color: #fff;
}
.user-input {
  border-top: 1px solid rgb(244, 244, 244);
}
.pinned-message {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.pinned-message.me {
  background-color: rgb(31, 135, 120);
  color: #fff;
}
.pinned-message.partner {
  background-color: rgb(241, 241, 241);
  color: rgba(0, 0, 0, 0.87);
}
.pinned-messages {
  width: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;
  &__indicators {
    position: absolute;
    left: -10px;
    top: 0;
    height: 100%;
    @include flex-column;
    justify-content: space-around;
    cursor: pointer;
  }
  &__body {
    font-size: 13px;
    line-height: 13px;
  }
}
.message {
  transition: opacity 0.4s;
  &__actions {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    display: none;
    z-index: 1;
  }
  .v-card:hover &__actions {
    display: block;
  }
}
.me {
  align-self: flex-end;
  .message__actions {
    left: 0;
  }
}
.partner .message__actions {
  right: 0;
}
.highlight {
  opacity: 0.3;
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(16, 65, 57);
}
.active {
  background-color: rgb(36, 197, 173);
}
.contact-details {
  &__tabs {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
  }
  &__tab {
    padding: 10px 2px;
    min-width: 60px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.4s;
    &:hover {
      background-color: #e2e2e2;
    }
    &--active {
      font-weight: 600;
      color: rgb(31, 135, 120);
      border-bottom: 2px solid rgb(31, 135, 120);
    }
  }
}
.color-item-1::before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #b14754;
  margin-right: 10px;
}
.color-item-2::before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ead99a;
  margin-right: 10px;
}
.color-item-3::before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0000ff;
  margin-right: 10px;
}
.color-item-4::before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffa500;
  margin-right: 10px;
}
</style>
